export default class ViewportMapBounds {
    constructor({ northeast = {}, southwest = {}, location = {} }) {
        this.lat = location.lat;
        this.lon = location.lon;
        this.nwLat = northeast.lat;
        this.nwLng = northeast.lng;
        this.seLat = southwest.lat;
        this.seLng = southwest.lng;
    }
}
